import React, { useEffect, useState } from "react";
import LoginImage from "../../../../src/images/login-images.jpg";
import "../Login/style.css";
import { Link } from "react-router-dom";
import ToastHandle from "../../helpers/ToastMessage";
import { useNavigate } from "react-router-dom";
import { ButtonLoading } from "../../helpers/MainLoader";
import { forgotPassword, userLogin } from "../../../Api/ExportApi";
import { isAuthenticated } from "../../helpers/AuthConditionCheck";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [loading, setLoading] = useState(false);
  const authLocation = isAuthenticated();

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError("");
    if (!value) {
      setEmailError("Email is required");
    }
  };


  const handleForgotPassword = async () => {

    if (!validateEmail(email)) {
      setEmailError("Invalid email format");
    } else {
      setLoading(true);
      setEmailError("");
      try {
        const res = await forgotPassword(email);

        if (res.status === 200) {
          ToastHandle("Password reset link sent successfully to your alternative email", 'success');
          setEmail("");
          setLoading(false);
        } else {
          ToastHandle("Something went wrong", 'danger');
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        ToastHandle(error.response?.data?.message, 'danger');
        setLoading(false);
      }
    }
  }


  useEffect(() => {
    if (authLocation) {
      navigate("/");
    } else {
      navigate("/forgot-password");
    }
  }, []);
  return (
    <>
      <div className="banner flex justify-center items-center flex-wrap py-10 lg:py-0">
        <div className="w-full md:w-1/2 px-[0]  2xl:px-[150px] mx-auto mb-4 lg:mb-0 ">
          <h1 className="md:px-10 px-5 font-semibold text-4xl text-center mb-8">Forgot Password</h1>
          <form className="space-y-6 md:px-10 px-5" action="#" method="POST">
            <div>
              <label
                for="email"
                className="block text-sm font-medium leading-4 text-gray-900"
              >
                Enter Email Address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  autocomplete="email"
                  required
                  placeholder="Enter your Email"
                  className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                />
                {emailError && <div className="text-red-500 mt-2">{emailError}</div>}
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-lg bg-[#2a79bd] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={handleForgotPassword}
                disabled={!email.trim() || loading}
              >
                {!loading ? "Forgot Password" : <ButtonLoading />}
              </button>
            </div>
          </form>
        </div>

        <div className="w-full md:w-1/2 flex items-start lg:items-center right-section">
          <img className="p-10" src={LoginImage} alt="login" />
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
