import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { TbLogout } from "react-icons/tb";
import { navLinks } from "../../constants/navItems";
import { RiEdit2Fill } from "react-icons/ri";
import { MdClose, MdFullscreen } from "react-icons/md";
import Composeemailmodel from "../ComposeEmailModel";
import { FiMinus } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

const Sidebar = ({ setOpen, open }) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [minimizeMode, setMinimizeMode] = useState(false);
  const [expandModeParent, setExpandModeParent] = useState(false);
  const [values, setValues] = useState({
    to: "",
    subject: "",
    cc: "",
    bcc: "",
    body: ""
  });

  const location = useLocation();
  const sidebarRef = useRef(null);

  const logoutHndl = () => {
    localStorage.clear();
    navigate("/login");
  };


  const handleMinimizeMode = (value) => {
    setMinimizeMode(value);
  }

  const handleExpandModeParent = (value) => {
    setExpandModeParent(value);
  }


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (window.innerWidth <= 767) {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setOpen("none");
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen]);

  return (
    <>
    <div  ref={sidebarRef} className={`sidebar `}>
      <div
        className={`sidebar-inner absolute md:static  ${
          open === "large"
            ? "w-[200px] "
            : open === "small"
            ? "w-[70px] bg-[#fff] border-r border-gray-300"
            : "hidden"
        }  `}
      >
        <div className="flex items-center justify-between sidebar-head">
          <Link to={'/'}
            className={` font-semibold text-2xl  text-black  text-left h-[66px] mx-auto flex items-center justify-center ${
              open === "large"
                ? "text-[2xl] text-black  bg-white p-3"
                : "text-[18px] bg-white p-2"
            } `}
          >
            LOGO
          </Link>
          <MdClose
            onClick={() => setOpen(open === "large" ? "none" : "large")}
            className="text-2xl cursor-pointer block md:hidden"
          />
        </div>

        <ul>
          <li className="highlight-btn bg-[#2a79bd]">
            {open === "large" ? (
              <Link
                onClick={() => {
                  setShowModal(true)
                  setMinimizeMode(false)
                  if (window.innerWidth <= 767) {
                    setOpen('none');
                  }
                }}
                className="text-[#fff] text-base flex items-center gap-[10px] p-4 font-semibold "
                to=""
              >
                <RiEdit2Fill className="text-[20px]" />

                {open === "large" && "Compose Email"}
              </Link>
            ) : (
              <Link
                onClick={() => {
                  setShowModal(true)
                  setMinimizeMode(false)
                }}
                className="highlight-min-width text-[#fff] bg-[#2a79bd] text-5xl flex items-center justify-center gap-[10px] px-4  border-0 border-[#2a79bd] hover:font-semibold hover:border border-white"
                to=""
              >
                <RiEdit2Fill className="text-[20px] " />

                {open === "large" && "Compose Email"}
              </Link>
            )}
          </li>
        </ul>
        <div className="list ">
          <ul>
            {navLinks?.map((item) => (
              <li className="">
                {open === "large" ? (
                  <Link
                    className={`${
                      location.pathname === item?.link || (location.pathname === '/' && item?.link === '/inbox') ? "active_link" : ''
                    } text-[#666] text-base flex items-center gap-[10px] p-4 `}
                    to={item?.link}
                  >
                    {item?.icon}
                    {item?.label}
                  </Link>
                ) : (
                  <Link
                    className={`
                      ${location.pathname === item?.link && "active_link-new"}
                      text-[#2a79bd] text-2xl  flex items-center justify-center gap-[10px] p-4 hover:bg-[#2a79bd] hover:text-white hover-min-width`}
                    to={item?.link}
                  >
                    {item?.icon}
                  </Link>
                )}
              </li>
            ))}
          </ul>
          <ul>
            <li className="highlight-btn bg-[#2a79bd]">
              {open === "large" ? (
                <Link
                  className="text-[#fff] text-base flex items-center gap-[10px] p-4 font-semibold"
                  to="/login"
                  onClick={logoutHndl}
                >
                  <TbLogout className="text-[20px]" />

                  {open === "large" && "Logout"}
                </Link>
              ) : (
                <Link
                  className="text-[#fff] text-2xl flex items-center justify-center gap-[10px] p-4 hover-min-width"
                  to="/login"
                  onClick={logoutHndl}
                >
                  <TbLogout className="text-3xl" />

                  {open === "large" && "Logout"}
                </Link>
              )}
            </li>
          </ul>
        </div>
      </div>
        <Composeemailmodel
          showModal={showModal}
          handleMinimizeMode={handleMinimizeMode}
          closeModel={() => setShowModal(false)}
          expandModeParent={expandModeParent}
          handleExpandModeParent={handleExpandModeParent}
          storedValues={values}
          minimizeMode={minimizeMode}
        />
    </div>

    {minimizeMode && showModal && (
        <div className="w-[240px] bg-white z-50 fixed bottom-0 right-10 rounded-tl-lg rounded-tr-lg" style={{boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px'}}>
          <div className="header flex items-start justify-between rounded py-2 px-3 bg-[#eee] ">
            <h5 className="text-[#333] font-semibold text-[14px]" onClick={() => {
              setMinimizeMode(false)
              }}>
              New Message
            </h5>
            <div className="flex gap-0 items-center">
              <button className="ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                <span className="bg-transparent  text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                  <FiMinus onClick={() => {
                    handleMinimizeMode(!minimizeMode)
                    }} />
                </span>
              </button>
              <button className="ml-auto bg-transparent border-0 text-black  float-right text-3xl leading-none font-semibold outline-none focus:outline-none">
                <span className="bg-transparent text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                  <MdFullscreen onClick={() => {
                    setMinimizeMode(false)
                    setExpandModeParent(true)
                  }} />
                </span>
              </button>
              <button
                onClick={() => {
                  setShowModal(false)
                  setMinimizeMode(false)
                }}
                className="close-btn ml-auto bg-transparent border-0 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              >
                <span className="bg-transparent text-[#333] h-6 w-6 text-xl block outline-none focus:outline-none">
                  <IoMdClose />
                </span>
              </button>
            </div>
          </div>
        </div>
      )}

    </>
  );
};

export default Sidebar;
