import React, { useEffect, useState } from "react";
import LoginImage from "../../../../src/images/login-images.jpg";
import "../Login/style.css";
import { Link, useParams } from "react-router-dom";
import ToastHandle from "../../helpers/ToastMessage";
import { useNavigate } from "react-router-dom";
import { ButtonLoading } from "../../helpers/MainLoader";
import {
  forgotPassword,
  resetPassword,
  userLogin,
  verifyToken,
} from "../../../Api/ExportApi";
import { isAuthenticated } from "../../helpers/AuthConditionCheck";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isValidToken, setIsValidToken] = useState(false);
  const [loading, setLoading] = useState(false);

  const { token } = useParams();

  useEffect(() => {
    const getVerifyToken = async () => {
      try {
        const response = await verifyToken(token);

        if (response.data?.valid) {
          setIsValidToken(true);
        } else {
          setIsValidToken(false);
        }
      } catch (error) {
        console.error("Error verifying token:", error);
        setIsValidToken(false);
      }
    };

    if (token) {
      getVerifyToken();
    } else {
      setIsValidToken(false);
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setLoading(true);

    try {
      const res = await resetPassword(token, password);

      if (res.status === 200) {
        ToastHandle("Password reset successfully", "success");
        setTimeout(() => {
          navigate("/login");
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      ToastHandle(error.response?.data, "danger");
      setLoading(false);
      console.log(error);
    }
  };

  return (
    <>
      <div className="banner flex justify-center items-center flex-wrap pt-10 lg:py-0">
        <div className="w-full lg:w-1/2 px-[0]  2xl:px-[150px] mx-auto mb-4 lg:mb-0 ">
          {isValidToken ? (
            <>
              <h1 className="md:px-10 px-5 font-semibold text-4xl mb-8 w-full text-center">
                Reset Password
              </h1>
              <form
                className="space-y-6 md:px-10 px-5"
                action="#"
                method="POST"
              >
                <div>
                  <label
                    for="password"
                    className="block text-sm font-medium leading-4 text-gray-900"
                  >
                    New Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                        setError("");
                      }}
                      autocomplete="email"
                      required
                      placeholder="Enter your password"
                      className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                    />
                  </div>
                </div>

                <div>
                  <label
                    for="password"
                    className="block text-sm font-medium leading-4 text-gray-900"
                  >
                    Confirm Password
                  </label>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                        setError("");
                      }}
                      placeholder="Confirm Your Password"
                      autocomplete="email"
                      required
                      className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3"
                    />
                  </div>

                  {error && <div className="text-red-500 mb-4">{error}</div>}{" "}
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-lg bg-[#2a79bd] px-3 py-3 text-sm font-semibold leading-6 text-white shadow-sm hover:opacity-75 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    onClick={handleSubmit}
                    disabled={
                      !password.trim() || !confirmPassword.trim() || loading
                    }
                  >
                    {!loading ? "Reset Password" : <ButtonLoading />}
                  </button>
                </div>
              </form>
            </>
          ) : (
            <h2 className="lg:text-3xl md:text-2xl text-xl text-center text-black font-semibold">
              Invalid or Expired Token
            </h2>
          )}
        </div>

        <div className="w-full md:w-1/2 lg:w-1/2 flex items-start lg:items-center right-section">
          <img className="p-10" src={LoginImage} alt="login" />
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
