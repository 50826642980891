import React, { createContext, useState, useContext } from 'react';

// Create a Context
export const AppContext = createContext();

// Create a Provider Component
export const AppProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [authToken, setAuthToken] = useState(null);

    const login = (userData, token) => {
        setUser(userData);
        setAuthToken(token);
    };


    return (
        <AppContext.Provider value={{ user, authToken, login, }}>
            {children}
        </AppContext.Provider>
    );
};

// Custom hook to use the AppContext
export const useAppContext = () => useContext(AppContext);
