import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useLocation } from "react-router-dom";

function Layout({ children }) {
  const [open, setOpen] = useState("large");
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
      setOpen(window.innerWidth > 767 ? open : "none");
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (location.pathname === "/login" || location.pathname === "/register" || location.pathname === "/forgot-password" || location?.pathname.includes('/reset-password/')) {
    return children;
  }

  return (
    <div className="flex" style={{ height: `${height}px` }}>
      <Sidebar setOpen={setOpen} open={open} />
      <div className={`aside flex flex-col w-full`} open={open}>
        <Header setOpen={setOpen} open={open} />
        <div className="p-5 h-full  bg-[#f1f1f1] overflow-y-scroll">{children}</div>
      </div>
    </div>
  );
}

export default Layout;
