import "./style.css";
import Profile from "../../images/profile-picture.jpg";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { userDatabyid, userProfileUpdate } from "../../Api/ExportApi";
import ToastHandle from "../../Components/helpers/ToastMessage";
import { TbPhotoEdit } from "react-icons/tb";

const UserProfile = () => {
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [profilePicUrl, setProfilePicUrl] = useState(null);
  const [profilePicAWSUrl, setProfilePicAWSUrl] = useState(null);
  const [fileUpload, setFileUpload] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    const fetchUserData = async () => {
      const token = localStorage.getItem("token");
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const userId = decodedToken.userId;

          const resp = await userDatabyid(userId);
          setUserData(resp?.data);
          setEmail(resp?.data?.user?.email);
          setPassword(resp?.data?.user?.password);
          // Set the profile picture URL for preview
          setProfilePicAWSUrl(resp?.data?.user?.profilePic);
        } catch (err) {
          console.log(err.message);
        }
      } else {
        console.log("No token found");
      }
    };

    fetchUserData();
  }, []);

  const handleProfilePicChange = (event) => {
    const file = event.target.files[0];
    const MAX_FILE_SIZE = 5 * 1024 * 1024;
    const ALLOWED_FILE_TYPES = [
      "image/jpeg",
      "image/png",
      "image/jpg",
      "image/webp",
    ];

    if (file) {
      if (!ALLOWED_FILE_TYPES.includes(file.type)) {
        ToastHandle(
          "Please select a valid image file (JPEG, PNG, JPG, WEBP).",
          "danger"
        );
        return;
      }

      if (file.size > MAX_FILE_SIZE) {
        ToastHandle("Image size must not exceed 5MB.", "danger");
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        setProfilePicUrl(reader.result);
      };
      reader.readAsDataURL(file);

      setFileUpload(true);
    }
  };

  const dataURLtoFile = (dataURL, filename) => {
    console.log(dataURL);
    console.log(dataURL.split(""));

    const arr = dataURL.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const formData = new FormData();

    if (profilePicUrl) {
      try {
        const filename = profilePicUrl.split("/").pop().split("?")[0];
        const file = dataURLtoFile(profilePicUrl, filename);
        formData.append("profilePic", file);
      } catch (error) {
        console.error("Error converting dataURL to file:", error);
        setLoading(false);
        return;
      }
    }

    if (email !== userData?.user?.email) {
      formData.append("newEmail", email);
    }

    if (password !== userData?.user?.password) {
      formData.append("newPassword", password);
    }

    try {
      await userProfileUpdate(formData);
      ToastHandle("Profile updated successfully", "success");
      const token = localStorage.getItem("token");
      if (token) {
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.userId;
        const resp = await userDatabyid(userId);
        setUserData(resp.data);
        setProfilePicAWSUrl(resp.data.user.profilePic);
        setLoading(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      console.error("Failed to update profile:", error);
      setLoading(false);
    }
  };


  return (
    <>
      <div className="body flex justify-center items-start">
        <div className="userprofile bg-white  flex items-center flex-wrap md:flex-nowrap w-[100%] md:w-[75%] lg:w-[55%]  lg:w-[50%] 2xl:w-2/4 p-8 m-5 mx-auto md:mt-[100px] rounded-lg shadow md:gap-5 lg:gap-10  border">
          <div className="user-img lg:w-4/12 w-full h-auto mx-auto text-center">
            <div className="upload-btn-wrapper !overflow-visible">
              <div className="relative">
                {fileUpload ? <img
                  className="w-[150px] h-[150px] rounded-full mb-2 outline-0 border-0"
                  src={profilePicUrl}
                  alt="profile"
                /> : <img
                  className="w-[150px] h-[150px] rounded-full mb-2 outline-0 border-0"
                  src={profilePicAWSUrl}
                  alt="profile"
                />}
              <div className="mb-2 absolute right-[8px] top-28 z-[100]">
                <div
                  style={{ cursor: "pointer" }}
                  className="bg-slate-300 rounded-full p-1"
                  onClick={() =>
                    document.getElementById("profilePicInput").click()
                  }
                >
                  <TbPhotoEdit className="w-[23px] h-[23px]" />
                </div>
                <input
                  type="file"
                  accept="image/*"
                  id="profilePicInput"
                  onChange={handleProfilePicChange}
                  style={{ display: "none" }}
                />
              </div>
              </div>
            </div>
            <h3 className="text-2xl font-semibold">
              {userData?.user?.firstName} {userData?.user?.lastName}
            </h3>
            <p className="text-base">{userData?.user?.email}</p>
          </div>
          <div className="user-detail flex flex-col lg:w-8/12 w-full mt-5 md:mt-0">
            <h2 className="text-3xl font-semibold mb-3">Edit Profile</h2>
            <div className="flex flex-col mb-5 w-full">
              <label className="text-base text-[#666] font-normal">
                Username
              </label>
              <input
                className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3 focus:outline-0"
                type="text"
                placeholder="Enter your name"
                value={userData?.user?.email}
                readOnly
              />
            </div>
            <div className="flex flex-col w-full">
              <label className="text-base text-[#666] font-normal">
                Password
              </label>
              <input
                className="rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-[#008bfb] sm:text-sm sm:leading-6 px-3 focus:outline-0"
                type="password"
                placeholder="Enter your Password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              className="save bg-[#2a79bd] text-white rounded-md mt-5 py-3 w-full hover:opacity-75"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Saving..." : "Save"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
