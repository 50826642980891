export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();

  const isToday = 
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();

  if (isToday) {
      return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }

  const options = { month: 'short', day: '2-digit' };
  return date.toLocaleDateString('en-US', options);
};