import { jwtDecode } from "jwt-decode";

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  if (!token) return false;
  try {
    const decodedToken = jwtDecode(token || "");
    // Check if the token is expired
    return decodedToken.exp * 1000 > Date.now();
  } catch (err) {
    return false;
  }
};
