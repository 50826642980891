import { useState } from 'react';

const useCheckboxHandler = (initialEmails) => {
  const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);

  const handleCheckboxClick = (event, type, id, userId) => {
    event.stopPropagation();

    if (type === "all") {
      // Handle "Select All" functionality
      setSelectAllCheckbox(true);
      setSelectedItems(initialEmails?.map((email) => email?._id)); // Set all items as selected
    } else if (type === "none") {
      // Handle "Deselect All" functionality
      setSelectAllCheckbox(false);
      setSelectedItems([]); // Clear all selections
    } else if (type === "read") {
      setSelectAllCheckbox(false);
      console.log(userId);
      
      setSelectedItems(
        initialEmails
          ?.filter((email) => email.readBy?.includes(userId)) // Ensure readBy exists
          .map((email) => email?._id)
      );
    } else if (type === "unread") {      
      setSelectAllCheckbox(false);
      setSelectedItems(
        initialEmails
          ?.filter((email) => !email.readBy?.includes(userId)) // Ensure readBy exists
          .map((email) => email?._id)
      );
    }  else if (type === "starred") {      
      setSelectAllCheckbox(false);
      setSelectedItems(
        initialEmails
          ?.filter((email) => email.starredBy.includes(userId))
          .map((email) => email?._id)
      );
    } else if (type === "unstarred") {      
      setSelectAllCheckbox(false);
      setSelectedItems(
        initialEmails
          ?.filter((email) => !email.starredBy.includes(userId))
          .map((email) => email?._id)
      );
    } else if (type === "toggle") {
      if (selectedItems?.length > 0) {
        setSelectAllCheckbox(false);
        setSelectedItems([]);
      } else {
        setSelectAllCheckbox(true);
        setSelectedItems(initialEmails?.map((email) => email?._id));
      }
    } else {
      setSelectedItems((prevSelectedItems) => {
        const isSelected = prevSelectedItems.includes(id);
        const updatedSelectedItems = isSelected
          ? prevSelectedItems.filter((item) => item !== id)
          : [...prevSelectedItems, id];

        // Update "Select All" checkbox based on individual selections
        const areAllItemsSelected =
          updatedSelectedItems.length === initialEmails?.length;
        setSelectAllCheckbox(areAllItemsSelected);

        return updatedSelectedItems;
      });
    }
  };

  return { selectAllCheckbox, setSelectAllCheckbox, setSelectedItems,  selectedItems, handleCheckboxClick };
};

export default useCheckboxHandler;