import React, { useEffect, useState } from "react";
import { IoMdRefresh } from "react-icons/io";
import { IoTimeOutline, IoTrashOutline } from "react-icons/io5";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import useCheckboxHandler from "../../Components/hooks/useCheckboxHandler";
import {
  emptyTrash,
  getTrashEmails,
  permanentDelete,
  permanentDeleteAllEmails,
} from "../../Api/ExportApi";
import MarkAllRead from "../../Components/ui/MarkAllRead";
import ToastHandle from "../../Components/helpers/ToastMessage";

const Trash = () => {
  const [userId, setUserId] = useState();
  const [trashemails, setTrashEmails] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemPerPage] = useState(10);
  const [isLoading, setLoading] = useState(false);

  const {
    selectAllCheckbox,
    selectedItems,
    setSelectAllCheckbox,
    setSelectedItems,
    handleCheckboxClick,
  } = useCheckboxHandler(trashemails);

  const navigate = useNavigate();

  const fetchTrashEmails = async (page) => {
    setLoading(true);
    try {
      const resp = await getTrashEmails(page);
      setTrashEmails(resp?.data?.trashedEmails || []);
      // setTotalItems(resp?.data?.trashedEmails?.length || 0);

      setTotalItems(resp?.data?.total || 0);
      setItemPerPage(resp?.data?.limit || 0);
      setSelectAllCheckbox(false);
      setSelectedItems([]);
    } catch (err) {
      console.log("www");
      console.log("Error fetching trash emails:", err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTrashEmails(currentPage);
  }, [currentPage]);

  const handleRefresh = () => {
    fetchTrashEmails(currentPage);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handlePermanentlyDelete = async (emailId) => {
    if (!emailId) {
      ToastHandle("No email selected for deletion.", "danger");
      return;
    }

    try {
      // Call the API to permanently delete the email
      const resp = await permanentDelete(emailId);

      if (resp?.data?.message === "Email permanently deleted") {
        ToastHandle("Email permanently deleted", "success");
      } else {
        ToastHandle("Failed to delete email.", "danger");
      }

      // Close the modal and refresh the email list
      fetchTrashEmails(currentPage);
    } catch (error) {
      ToastHandle("Failed to delete email.", "danger");
      console.error("Error deleting email:", error);
    }
  };

  const handleDeleteAll = async () => {
    try {
      await permanentDeleteAllEmails(selectedItems);
      fetchTrashEmails(currentPage);
      ToastHandle(
        "All trashed emails have been deleted successfully",
        "success"
      );
    } catch (error) {
      ToastHandle("Failed to delete email.", "danger");
      console.error("Error deleting email:", error);
    }
  };

  const handleEmptyTrash = async () => {
    try {
      const trashEmailsIds = [];
      trashemails.forEach((item) => {
        trashEmailsIds.push(item._id);
      });

      await emptyTrash(trashEmailsIds);
      fetchTrashEmails(currentPage);
      ToastHandle(
        "All trashed emails have been deleted successfully",
        "success"
      );
    } catch (error) {
      ToastHandle("Failed to delete email.", "danger");
      console.error("Error deleting email:", error);
    }
  };

  return (
    <>
      <div className="py-2">
        <div className="bg-white inbox-header flex flex-wrap items-center justify-between px-3 pb-2">
          <div className="flex items-center gap-3 relative">
            <span className="block mt-1">
              {/* <MdOutlineCheckBoxOutlineBlank className='text-xl' /> */}
              <input
                checked={selectAllCheckbox && trashemails?.length > 0}
                type="checkbox"
                className="cursor-pointer"
                onChange={(e) => handleCheckboxClick(e, "toggle")}
              />
            </span>
            <div className="dropdown inline-block relative">
              <button className=" text-gray-700 font-semibold hover:bg-gray-200 py-2 px-1 rounded inline-flex items-center">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                </svg>
              </button>
              <ul className="dropdown-menu absolute hidden text-gray-700 pt-1">
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "all")}
                >
                  All
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "none")}
                >
                  None
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) => handleCheckboxClick(e, "read", null, userId)}
                >
                  Read
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) =>
                    handleCheckboxClick(e, "unread", null, userId)
                  }
                >
                  Unread
                </li>
                <li
                  className="rounded-t bg-white hover:bg-gray-200 py-2 px-4 block whitespace-no-wrap"
                  onClick={(e) =>
                    handleCheckboxClick(e, "starred", null, userId)
                  }
                >
                  Starred
                </li>
              </ul>
            </div>
            <button onClick={handleRefresh}>
              <IoMdRefresh className="text-2xl  hover:bg-gray-200 text-gray-500 rounded" />
            </button>
            <MarkAllRead
              emails={trashemails}
              selectedItems={selectedItems}
              userId={userId}
              currentPage={currentPage}
              refetchEmails={fetchTrashEmails}
              selectAllCheckbox={selectAllCheckbox}
            />
            {selectedItems?.length > 1 && (
              <button
                className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                onClick={handleDeleteAll}
              >
                Delete Forever
              </button>
            )}
            {trashemails?.length > 0 && (
              <button
                className="font-[500] text-gray-400 hover:bg-gray-200 p-1 rounded-sm"
                onClick={handleEmptyTrash}
              >
                Empty Trash Now
              </button>
            )}
          </div>
          <div className="flex items-center gap-3">
            <p className="text-gray-500 font-normal text-base hover:bg-gray-200 p-2 rounded">
              {startItem}-{endItem} of {totalItems}
            </p>
            <div className="flex items-center">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <MdOutlineChevronLeft className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
              </button>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <MdOutlineChevronRight className="text-gray-800 text-xl hover:bg-gray-200 rounded" />
              </button>
            </div>
          </div>
        </div>
        <div className="inbox-body border-t">
          <div className="inbox-body-inner">
            {/* <div className=' cursor-pointer item-active flex item-center justify-between gap-3 border-b p-3 hover:shadow-lg hover:bg-[#e4f3ff]'>
                            <div className='flex items-center  gap-2'>
                                <div className='gap-2 flex items-center'>
                                    <div className="inline-flex items-center cs-check">
                                        <label className="relative flex items-center rounded-full cursor-pointer" htmlFor="check">
                                            <input type="checkbox"
                                                className=" before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border-2 border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-transparent checked:before:bg-transparent hover:before:opacity-10"
                                                id="check" />
                                            <span
                                                className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" viewBox="0 0 20 20" fill="currentColor"
                                                    stroke="currentColor" stroke-width="1">
                                                    <path fill-rule="evenodd"
                                                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                        clip-rule="evenodd"></path>
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                    <button><IoTrashOutline className='text-xl text-gray-400  hover:text-white' /></button>
                                </div>
                                <div className=''>
                                    <p className='font-medium text-[14px] text-gray-700'>Lorem ipsum dolor sit, amet</p>
                                    <p className='font-normal text-[12px] text-gray-600'>Lorem ipsum dolor sit, amet Lorem ipsum dolor sit, amet</p>
                                </div>
                            </div>
                            <div className='gap-2 flex items-center hidden md:flex'>

                                <button><IoTrashOutline className='text-xl text-gray-400' /></button>
                                <button><IoTimeOutline className='text-xl text-gray-400' /></button>
                            </div>
                        </div> */}
            {trashemails?.length > 0 ? (
              <>
                {trashemails?.map((email) => (
                  <div
                    className=" cursor-pointer bg-white item flex item-center justify-between gap-3 border-b p-3 hover:shadow-lg"
                    key={email?._id}
                  >
                    <div className="flex items-center  gap-2">
                      <div className="gap-2 flex items-center">
                        <div className="inline-flex items-center">
                          <label
                            className="relative flex items-center rounded-full cursor-pointer"
                            htmlFor="check"
                          >
                            <input
                              type="checkbox"
                              className=" before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border-2 border-gray-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-transparent checked:before:bg-transparent hover:before:opacity-10"
                              id="check"
                              checked={
                                selectAllCheckbox ||
                                selectedItems.includes(email?._id)
                              }
                              name="checkbox"
                              onChange={(e) =>
                                handleCheckboxClick(e, "single", email?._id)
                              }
                            />
                            <span className="absolute text-gray-600 transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-3 w-3"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                stroke="currentColor"
                                stroke-width="1"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                  clip-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </label>
                        </div>
                        <button
                          onClick={() => handlePermanentlyDelete(email?._id)}
                        >
                          <IoTrashOutline className="text-xl text-gray-400" />
                        </button>
                      </div>
                      <div className="">
                        <p className="font-medium text-[14px] text-gray-700">
                          {email.subject ? email.subject : "(No subject)"}
                        </p>
                        <p
                          className="font-normal text-[12px] text-gray-600"
                          dangerouslySetInnerHTML={{
                            __html: email?.body?.slice(0, 40),
                          }}
                        />
                      </div>
                    </div>
                    <div className="gap-2 items-center  hidden md:flex">
                      <button
                        onClick={() => handlePermanentlyDelete(email?._id)}
                      >
                        <IoTrashOutline className="text-xl text-gray-400" />
                      </button>
                      <button>
                        <IoTimeOutline className="text-xl text-gray-400" />
                      </button>
                    </div>
                  </div>
                ))}{" "}
              </>
            ) : (
              <div className="w-fill flex justify-center items-center h-[50vh]">
                <div colSpan="3" className="text-center py-4">
                  No emails found
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Trash;
